import React, { FC } from 'react';
import styled from 'styled-components';
import StarIcons from './StarIcons';
import VerifiedIcon from './VerifiedIcon';
import { tablet } from 'styles/breakpoints';
import { useQuizData } from 'utils/hooks';

const ReviewCard: FC = () => {
  const data = useQuizData('reviews');
  return (
    <CardContainer>
      <ImageContainer>
        <StyledImage
          src="https://res.cloudinary.com/drfweekiv/image/upload/v1722256525/Frame_1214134088_1.png"
          alt="Happy user"
        />
      </ImageContainer>
      <StyledText>
        {data?.section1.review ||
          '"This app really helped me manage my uncontrollable ADHD symptoms. Now I understand that there’s nothing wrong with me, it’s just who I am. Since using Effecto, my life got so much easier. I’m finally happy."'}
      </StyledText>
      <UserRatingContainer>
        <div>
          <UserName>Lucas W., 36</UserName>
          <StarsContainer>
            <StarIcons starCount={5} />
          </StarsContainer>
        </div>
        <VerifiedBadge>
          <VerifiedIcon />
          <VerifiedText>Verified</VerifiedText>
        </VerifiedBadge>
      </UserRatingContainer>
    </CardContainer>
  );
};

export default ReviewCard;

const CardContainer = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 343px;
  padding: 16px;
  border-radius: 10px;
  margin-bottom: 20px;
  border: 1px solid #eeeef0;

  @media ${tablet} {
    margin-bottom: 16px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 313px;
  margin-bottom: 12px;
`;

const StyledImage = styled.img`
  object-fit: cover;
  width: 100%;
  border-radius: 10px;
`;

const StyledText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.304px;
  color: #000;
  margin: 0;
  padding-bottom: 12px;
  border-bottom: 1px solid rgb(142, 144, 154, 15%);
`;

const UserRatingContainer = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const VerifiedBadge = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background: #00a676;
  border-radius: 16px;
`;

const VerifiedText = styled.span`
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.16px;
  color: #fff;
`;
const UserName = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.14px;
  margin-bottom: 8px;
`;
const StarsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;
