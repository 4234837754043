interface UserReview {
  author: string;
  comment: string;
}

export const userReviews: UserReview[] = [
  {
    author: 'Sebastien',
    comment:
      'It not only allows me to track my mood and ADHD symptoms but also helps me see patterns over time.',
  },
  {
    author: 'Milhelm',
    comment:
      'Really helped me with procrastinating and my ADHD. Big thanks to the creators.',
  },
  {
    author: 'Tess B.',
    comment: `It helps me track myself throughout the day and its a safe space to check in, great app.`,
  },
  {
    author: 'Jonas.',
    comment:
      'Instead of having different apps for fitness, meditation, nutrition etc., Effecto tracks all these things.',
  },
  {
    author: 'Johnathan',
    comment:
      'It\'s really nice when I get insights into what might have caused changes in my mood.',
  },
  {
    author: 'Nate G',
    comment:
      'Effecto provides so much helpful info and charts which help me understand what\'s going on with my patterns.',
  },
];
