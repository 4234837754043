interface VideoReview {
  url: string;
  title: string;
  description: string;
  name: string;
}

export const videoReviews: VideoReview[] = [
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1722256248/effecto-1.mp4',
    title: 'Great app!',
    description: `"Managing my daily tasks has been super messy. Effecto helped me stay on track and prioritize what needs to be done. I now feel more in control."`,
    name: 'Jessica S.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1722256249/effecto-2.mp4',
    title: 'I’m more efficient now',
    description: `"I’ve been able to organize my tasks better and actually complete them. It’s made my workdays so much more efficient and less stressful."`,
    name: 'Emilia',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1722256249/effecto-3.mp4',
    title: 'Much less distraction',
    description: `"Using Effecto has significantly improved how I handle my daily responsibilities. Now, I don’t forget tasks, which has been a huge relief."`,
    name: 'Camilla S.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1722256248/effecto-4.mp4',
    title: 'Beat my procrastination!',
    description: `"I used to procrastinate a lot, but the app has helped me break tasks down. Now, I’m more productive and less anxious about my workload."`,
    name: 'Anya',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1722256248/effecto-5.mp4',
    title: 'I’m way less overwhelmed',
    description: `"Managing my work tasks used to be chaotic. Now it’s way easier to meet deadlines. I’m much more productive and less stressed now."`,
    name: 'Ashley W.',
  },
];
