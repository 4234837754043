interface SuccessStory {
  imgUrl: string;
  story: string;
  author: string;
}

export const SuccessStories: SuccessStory[] = [
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1722256554/Frame_1214134129_1.png',
    story: `"Since using Effecto, my productivity has increased, and I’ve become much more organized in both my personal and professional life. I no longer feel overwhelmed by my tasks and can tackle projects with greater efficiency."`,
    author: 'Natalie, 25',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1722256554/Frame_1214134129_2.png',
    story:
      '"I used to struggle keeping track of my deadlines, it was so overwhelming. The task management features help me break down my projects into smaller, manageable steps and set reminders, so I don’t forget important dates."',
    author: 'Rebecca S., 26',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1722256545/Frame_1214134128_17.png',
    story:
      '"This app has transformed how I manage my ADHD. Piles of laundry on my bed are gone now and I am much more accepting of myself. I love how addicting this app is, in a good way of course!  Recommend to anyone with ADHD symptoms."',
    author: 'Emma W., 23',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1722256544/Frame_1214134128_18.png',
    story:
      '"Ever since quitting my adhd meds, I have been on edge constantly. I’m glad I found Effecto, it provides me with different focus exercises and meditation sessions, which are really effective and calm me down so well. 10/10"',
    author: 'Thompson L., 30',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1722256553/Frame_1214134129_3.png',
    story:
      '"It’s been a game-changer for staying organized and on top of my tasks, which is crucial for managing my ADD. It’s super easy to use, and I’ve noticed a big difference in my daily life. I feel more in control and less stressed."',
    author: 'Nathan M, 32',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1722256555/Frame_1214134128_19.png',
    story:
      '"The focus tools and personalized lessons have helped me stay on top of my responsibilities. I’ve seen a significant improvement in my ability to manage my time and complete tasks efficiently. Highly recommend!"',
    author: 'Carol, 24',
  },
];
