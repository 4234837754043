import React, { FC } from 'react';
import {
  SectionHeading,
  SectionSubheading,
  StyledSection,
} from 'pages/reviews';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { tablet, useQuery } from 'styles/breakpoints';
import { SuccessStories } from 'utils/successStories';
import SuccessCard from './SuccessCard';
import styled from 'styled-components';
import CtaButton from './CtaButton';
import { useQuizData } from 'utils/hooks';

const SuccessStoriesSection: FC = () => {
  const { isTablet } = useQuery();
  const data = useQuizData('reviews');
  return (
    <StyledSection
      backgroundColor="#fff"
      sectionPadding="56px 16px"
      marginTop="0px"
    >
      <SectionHeading marginBottom="4px">
        {data?.section4?.title || 'Effecto success stories'}
      </SectionHeading>
      <SectionSubheading marginBottom={isTablet ? '20px' : '38px'}>
        {data?.section4?.subtitle ||
          'How Effecto enhances focus and organization'}
      </SectionSubheading>
      <CarouselContainer>
        <SwiperStyled
          modules={[Pagination]}
          pagination={{ clickable: true }}
          allowTouchMove={true}
          effect="slide"
          slidesPerView={'auto'}
          spaceBetween={isTablet ? 15 : 22}
        >
          {data?.section4
            ? data?.section4?.userReviews.map((story, index) => (
                <SwiperSlide key={index}>
                  <SuccessCard
                    imgUrl={story.imgUrl}
                    author={story.author}
                    story={story.story}
                  />
                </SwiperSlide>
              ))
            : SuccessStories.map((story, index) => (
                <SwiperSlide key={index}>
                  <SuccessCard
                    imgUrl={story.imgUrl}
                    author={story.author}
                    story={story.story}
                  />
                </SwiperSlide>
              ))}
        </SwiperStyled>
      </CarouselContainer>
      <ButtonContainer>
        <CtaButton
          buttonText={data?.section4.buttonTitle || 'Start your journey'}
        />
      </ButtonContainer>
    </StyledSection>
  );
};

export default SuccessStoriesSection;

const CarouselContainer = styled.div`
  width: 100%;
  max-width: 1080px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SwiperStyled = styled(Swiper)`
  .swiper-pagination {
    position: relative !important;
    width: 100%;
  }

  .swiper-slide {
    width: 343px !important;
    margin-bottom: 28px;

    @media ${tablet} {
      margin-bottom: 20px !important;
    }
  }

  .swiper-pagination-bullet {
    opacity: unset;
    margin: 0 0.25rem;
    background-color: rgba(115, 137, 156, 0.5);
    &-active {
      background-color: #05a56f;
      width: 8px;
    }
  }
`;

const ButtonContainer = styled.div`
  max-width: 343px;
  width: 100%;
  margin-top: 16px;

  @media ${tablet} {
    margin-top: 8px;
  }
`;
