import React, { FC } from 'react';
import styled from 'styled-components';
import VideoReviewsCard from './VideoReviewsCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { tablet, useQuery } from 'styles/breakpoints';
import { videoReviews } from 'utils/videoReviews';
import {
  SectionHeading,
  SectionSubheading,
  StyledSection,
} from 'pages/reviews';
import { useQuizData } from 'utils/hooks';

const VideoReviewsSection: FC = () => {
  const { isTablet } = useQuery();
  const data = useQuizData('reviews');

  return (
    <StyledSection
      backgroundColor="#fff"
      sectionPadding="56px 16px"
      marginTop="0px"
    >
      <SectionHeading marginBottom="4px">
        {data?.section2.title || 'Real user experiences with Effecto'}
      </SectionHeading>
      <SectionSubheading marginBottom={isTablet ? '20px' : '38px'}>
        {data?.section2.subtitle || 'Discover the impact of Effecto app'}
      </SectionSubheading>
      <CarouselContainer>
        <SwiperStyled
          modules={[Pagination]}
          pagination={{ clickable: true }}
          allowTouchMove={true}
          effect="slide"
          slidesPerView={'auto'}
          spaceBetween={isTablet ? 15 : 22}
        >
          {data?.section2
            ? data?.section2.videoReviews.map((review, index) => (
                <SwiperSlide key={index}>
                  <VideoReviewsCard
                    key={index}
                    video={review.url}
                    title={review.title}
                    description={review.description}
                    reviewer={review.name}
                  />
                </SwiperSlide>
              ))
            : videoReviews.map((review, index) => (
                <SwiperSlide key={index}>
                  <VideoReviewsCard
                    key={index}
                    video={review.url}
                    title={review.title}
                    description={review.description}
                    reviewer={review.name}
                  />
                </SwiperSlide>
              ))}
        </SwiperStyled>
      </CarouselContainer>
    </StyledSection>
  );
};

export default VideoReviewsSection;

const CarouselContainer = styled.div`
  width: 100%;
  max-width: 1110px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SwiperStyled = styled(Swiper)`
  .swiper-pagination {
    position: relative !important;
    width: 100%;
  }

  .swiper-slide {
    width: 260px !important;
    margin-bottom: 28px;

    @media ${tablet} {
      margin-bottom: 16px;
    }
  }

  .swiper-pagination-bullet {
    opacity: unset;
    margin: 0 0.25rem;
    background-color: rgba(115, 137, 156, 0.5);
    &-active {
      background-color: #05a56f;
      width: 8px;
    }
  }
`;
