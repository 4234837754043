import React, { useEffect } from 'react';
import Header from '../../components/reviews/Header';
import MainReviewSection from 'components/reviews/MainReviewSection';
import VideoReviewsSection from 'components/reviews/VideoReviewsSection';
import SatisfiedUsersSection from 'components/reviews/SatisfiedUsersSection';
import SuccessStoriesSection from 'components/reviews/SuccessStoriesSection';
import styled from 'styled-components';
import Footer from 'components/reviews/Footer';
import { Seo } from 'components';
import LCFooter from 'components/LCFooter';
import { useQuizData } from 'utils/hooks';

const Reviews = () => {
  const disclaimer = useQuizData('shortDisclaimer');
  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);
  return (
    <>
      <Seo
        title="Effecto Reviews"
        name="Effecto Reviews"
        description="Check out these effecto app reviews and see why our personalized adhd app has over 154,000 active users."
      />
      <Header isMobileApp={false} />
      <MainReviewSection />
      <VideoReviewsSection />
      <SatisfiedUsersSection />
      <SuccessStoriesSection />
      <LCFooter
        disclaimerText={disclaimer || ['© 2024 Effecto. All rights reserved.']}
      />
    </>
  );
};

export default Reviews;

export const StyledSection = styled.section<{
  backgroundColor: string;
  sectionPadding: string;
  marginTop?: string;
}>`
  height: fit-content;
  width: 100%;
  background: ${({ backgroundColor = '#f6f4ef' }) => backgroundColor};
  padding: ${({ sectionPadding = '56px 16px' }) => sectionPadding};
  margin-top: ${({ marginTop }) => marginTop};
  display: flex;
  align-items: center;
  flex-direction: column;

  p,
  strong,
  a,
  h1,
  h2,
  span {
    font-family: Helvetica;
  }

  p,
  strong,
  h1 {
    color: #000 !important;
  }

  @media (max-width: 48em) {
    padding: 38px 16px;
  }
`;

export const SectionHeading = styled.h1<{ marginBottom: string }>`
  color: #000;
  font-weight: 700;
  font-size: 28px;
  line-height: 140%;
  text-align: center;
  margin-top: 0;
  margin-bottom: ${({ marginBottom = '8px' }) => marginBottom};
`;

export const SectionSubheading = styled.h2<{ marginBottom: string }>`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #595b60;
  margin-top: 0;
  margin-bottom: ${({ marginBottom = '10px' }) => marginBottom};
`;
